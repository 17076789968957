import React, {useEffect} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import UserProfile from "./UserProfile";

import {useSelector} from "react-redux";
import {CheckOutlined, HomeFilled, HourglassOutlined} from "@ant-design/icons";
import {FaUsers, FaBuilding, FaBriefcase, FaBook, FaClipboardList} from "react-icons/fa";
import Helpers from "../../util/Helpers";
import { MAIN_MENU } from "../../constants/ServerUrl";


const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed}) => {

    let {navStyle, themeType} = useSelector(({settings}) => settings);
    let {pathname} = useSelector(({common}) => common);
    const {authorizations} = useSelector(({auth}) => auth);

    let selectedKeys = pathname.substr(1);
    let defaultOpenKeys = selectedKeys.split('/')[1];
    // const defaultOpenKeys = selectedKeys.split('/')[1];

    return (
        <>


            <div className="flex items-center justify-center py-2 px-2">
                <UserProfile/>
                {/*<AppsNavigation/>*/}
            </div>


            <Menu
                className="bg-blue text-white text-center text-base font-medium my-5"
                mode="inline"
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}>

                <MenuItemGroup key="dashboard" className="mb-5">

                    <Menu.Item key="home">
                        {/* <Link to="/home" className="flex">
                            <div className="text-2xl">
                                <HomeFilled/>
                            </div>
                            <span className="mx-3 py-1">Home</span>
                        </Link> */}
                        <div  className="flex"  onClick={() => {
                                window.location.href = MAIN_MENU
                        }}>
                            <div className="text-2xl">
                                <HomeFilled/>
                            </div>
                            <span className="mx-3">Home</span>
                        </div>
                        
                    </Menu.Item>
                    <Menu.Item>
                         <Link to="/home" className="flex">
                            <div className="text-2xl">
                                <HourglassOutlined/>
                            </div>
                            <span className="mx-3">Dashboard</span>
                        </Link>
                    </Menu.Item>
                    {Helpers.authorizeMe(authorizations[7], 'view', 29) && (


                        <SubMenu key="polls"
                                 title={<div className="flex my-1 text-white ">
                                     <div className="text-lg">

                                         <CheckOutlined/>
                                     </div>
                                     <div className="px-4 -my-1">Voting</div>
                                 </div>}>


                            <Menu.Item key="voting/polls">
                                <Link to="/voting/polls">
                                    <span className="pl-7 text-gray-200 text-sm">Polls</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="voting/voters-group">
                                <Link to="/voting/voters-group">
                                    <span className="pl-7 text-gray-200 text-sm">Voters Group</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="voting/votes">
                                <Link to="/voting/votes">
                                    <span className="pl-7 text-gray-200 text-sm">Votes</span>
                                </Link>
                            </Menu.Item>

                        </SubMenu>
                    )}

                </MenuItemGroup>

                <MenuItemGroup key="payroll" className="gx-menu-group"
                               title={<div className="px-3 text-left text-white">EMPLOYEE SERVICES</div>}>


                    <SubMenu key="company"
                             title={<div className="flex my-3 text-white ">
                                 <div className="text-lg">

                                     <FaBuilding/>
                                 </div>
                                 <div className="px-6 -my-3">Company</div>
                             </div>}>


                        <Menu.Item key="company/announcements">
                            <Link to="/company/announcements">
                                <span className="pl-7 text-gray-200 text-sm">Announcements</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/candidates">
                            <Link to="/company/candidates">
                                <span className="pl-7 text-gray-200 text-sm">Candidates</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/validations">
                            <Link to="/company/validations">
                                <span className="pl-7 text-gray-200 text-sm">Company Validations</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/departments">
                            <Link to="/company/departments">
                                <span className="pl-7 text-gray-200 text-sm">Departments</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/usergroups">
                            <Link to="/company/usergroups">
                                <span className="pl-7 text-gray-200 text-sm">User Groups</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/document-types">
                            <Link to="/company/document-types">
                                <span className="pl-7 text-gray-200 text-sm">Document Types</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/documents">
                            <Link to="/company/documents">
                                <span className="pl-7 text-gray-200 text-sm">Documents</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/events">
                            <Link to="/company/events">
                                <span className="pl-7 text-gray-200 text-sm">Surveys</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/company-holidays">
                            <Link to="/company/company-holidays">
                                <span className="pl-7 text-gray-200 text-sm">Holidays</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/job-roles">
                            <Link to="/company/job-roles">
                                <span className="pl-7 text-gray-200 text-sm">Job Roles</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/letters">
                            <Link to="/company/letters">
                                <span className="pl-7 text-gray-200 text-sm">Templates</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/notifications">
                            <Link to="/company/notifications">
                                <span className="pl-7 text-gray-200 text-sm">Notifications</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/pay-structures">
                            <Link to="/company/pay-structures">
                                <span className="pl-7 text-gray-200 text-sm">Pay Structures</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/rates">
                            <Link to="/company/rates">
                                <span className="pl-7 text-gray-200 text-sm">Rates</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/onboarding-tasks">
                            <Link to="/company/onboarding-tasks">
                                <span className="pl-7 text-gray-200 text-sm">Tasks</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/helpdesk">
                            <Link to="/company/helpdesk">
                                <span className="pl-7 text-gray-200 text-sm">Help Desk</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="company/guides">
                            <a download href="/User Guide - Home and Company Tabs_Employee Management Module.pdf">
                                <span className="pl-7 text-gray-200 text-sm">Company Guide</span>
                            </a>
                        </Menu.Item>

                    </SubMenu>
                    <SubMenu key="employee"
                             title={<div className="flex my-3 text-white ">
                                 <div className="text-lg">

                                     <FaUsers/>
                                 </div>
                                 <div className="px-7 -my-3">Employee</div>
                             </div>}>


                        <Menu.Item key="employee/appraisal-documents">
                            <Link to="/employee/appraisal-documents">

                                <span className="pl-7 text-gray-200 text-sm">Appraisal Documents</span>
                            </Link>
                        </Menu.Item>
                        {/* <Menu.Item key="employee/attendance">
                            <Link to="/employee/attendance">

                                <span className="pl-7 text-gray-200 text-sm">Attendance</span>
                            </Link>
                        </Menu.Item> */}
                        <Menu.Item key="employee/covid-vaccinations">
                            <Link to="/employee/covid-vaccinations">
                                <span className="pl-7 text-gray-200 text-sm">Covid Vaccinations</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="employee/document-status">
                            <Link to="/employee/document-status">
                                <span className="pl-7 text-gray-200 text-sm">Document Status</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="employee/employee-center">
                            <Link to="/employee/employee-center">
                                <span className="pl-7 text-gray-200 text-sm">Employee Center</span>
                            </Link>
                        </Menu.Item>


                        <Menu.Item key="employee/employee-files">
                            <Link to="/employee/employee-files">
                                <span className="pl-7 text-gray-200 text-sm">Employee Files</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="employee/employee-rates">
                            <Link to="/employee/employee-rates">

                                <span className="pl-7 text-gray-200 text-sm">Employee Rates</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="employee/employee-variations">
                            <Link to="/employee/employee-variations">
                                <span className="pl-7 text-gray-200 text-sm">Employee Variations</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="employee/employee-verifications">
                            <Link to="/employee/employee-verifications">
                                <span className="pl-7 text-gray-200 text-sm">Employee Verifications</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="employee/profile-verifications">
                            <Link to="/employee/profile-verifications">
                                <span className="pl-7 text-gray-200 text-sm">Profile Update </span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="employee/exits">
                            <Link to="/employee/exits">
                                <span className="pl-7 text-gray-200 text-sm">Exits</span>
                            </Link>
                        </Menu.Item>


                        <Menu.Item key="employee/salary-advances">
                            <Link to="/employee/salary-advances">
                                <span className="pl-7 text-gray-200 text-sm">Salary Advances</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="employee/supervisors">
                            <Link to="/employee/supervisors">
                                <span className="pl-7 text-gray-200 text-sm">Supervisors</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="employee/training-schedules">
                            <Link to="/employee/training-schedules">
                                <span className="pl-7 text-gray-200 text-sm">Trainings</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="employee/guides">
                            <a download href="/User Guide - Employee Tab_Employee Management Module.pdf">
                                <span className="pl-7 text-gray-200 text-sm">Employee Guide</span>
                            </a>
                        </Menu.Item>

                    </SubMenu>
                    <SubMenu key="issues"
                             title={<div className="flex my-3 text-white ">
                                 <div className="text-lg">

                                     <FaClipboardList/>
                                 </div>
                                 <div className="px-7 -my-3">Issues</div>
                             </div>}>


                        <Menu.Item key="issues/blacklist">
                            <Link to="/issues/blacklist">

                                <span className="pl-7 text-gray-200 text-sm">Blacklist</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="issues/cases">
                            <Link to="/issues/cases">

                                <span className="pl-7 text-gray-200 text-sm">Cases</span>
                            </Link>
                        </Menu.Item>


                        <Menu.Item key="issues/confidential-notes">
                            <Link to="/issues/confidential-notes">
                                <span className="pl-7 text-gray-200 text-sm">Confidential Notes</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="issues/employee-queries">
                            <Link to="/issues/employee-queries">
                                <span className="pl-7 text-gray-200 text-sm">Employee Queries</span>
                            </Link>
                        </Menu.Item>

                        <Menu.Item key="issues/guides">
                            <a download href="/User Guide - Issues Tab_Employee Management Module.pdf">
                                <span className="pl-7 text-gray-200 text-sm">Issues Guide</span>
                            </a>
                        </Menu.Item>


                    </SubMenu>
                    <SubMenu key="leave-tracker"
                             title={<div className="flex my-3 text-white ">
                                 <div className="text-lg">

                                     <FaBriefcase/>
                                 </div>
                                 <div className="px-7 -my-3">Leave Tracker</div>
                             </div>}>


                        <Menu.Item key="leave-tracker/leave">
                            <Link to="/leave-tracker/leave">

                                <span className="pl-7 text-gray-200 text-sm">Leave</span>
                            </Link>
                        </Menu.Item>


                        <Menu.Item key="leave-tracker/leave-applications">
                            <Link to="/leave-tracker/leave-applications">
                                <span className="pl-7 text-gray-200 text-sm">Leave Applications</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="leave-tracker/leave-applications-others">
                            <Link to="/leave-tracker/leave-applications-others">
                                <span className="pl-7 text-gray-200 text-sm">General Leave Applications</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="leave-tracker/leave-resumption">
                            <Link to="/leave-tracker/leave-resumption">
                                <span className="pl-7 text-gray-200 text-sm">Leave Resumption</span>
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="leave-tracker/guides">
                            <a download href="/User Guide - Leave Tracker Tab_Employee Management Module.pdf">
                                <span className="pl-7 text-gray-200 text-sm">Leave Guide</span>
                            </a>
                        </Menu.Item>
                    </SubMenu>
                            {Helpers.authorizeMe(authorizations[8],'view',53) &&
                            <SubMenu key="attendance"
                            title={<div className="flex my-3 text-white ">
                                <div className="text-lg">

                                    <FaBriefcase/>
                                </div>
                                <div className="px-7 -my-3">Attendance</div>
                            </div>}>


                       <Menu.Item key="attendance/reports">
                           <Link to="/attendance/reports">

                               <span className="pl-7 text-gray-200 text-sm">Attendance Report</span>
                           </Link>
                       </Menu.Item>

                       <Menu.Item key="attendance/daily_attendance_status">
                           <Link to="/attendance/daily_attendance_status">

                               <span className="pl-7 text-gray-200 text-sm">Attendance Insights</span>
                           </Link>
                       </Menu.Item>
                       <Menu.Item key="totalhours">
                           <Link to="/attendance/totalhours">

                               <span className="pl-7 text-gray-200 text-sm">Attendance (Hours)</span>
                           </Link>
                       </Menu.Item>
                     

                       


                       <Menu.Item key="attendance/regularization">
                           <Link to="/attendance/regularization">
                               <span className="pl-7 text-gray-200 text-sm">Regularization</span>
                           </Link>
                       </Menu.Item>
                 
                   </SubMenu>
                            }
                    

                    {Helpers.authorizeMe(authorizations[8], 'view', 34) && (
                        <SubMenu key="payroll"
                                 title={<div className="flex my-3 text-white ">
                                     <div className="text-lg">

                                         <FaBook/>
                                     </div>
                                     <div className="px-8 -my-3">Payroll</div>
                                 </div>}>

                            {Helpers.authorizeMe(authorizations[8], 'view', 34) && (
                                <Menu.Item key="payroll/payroll-center">
                                    <Link to="/payroll/payroll-center">

                                        <span className="pl-7 text-gray-200 text-sm">Payroll Center</span>
                                    </Link>
                                </Menu.Item>)}


                        </SubMenu>)}

                </MenuItemGroup>

            </Menu>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;

